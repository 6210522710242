











































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Application, IconsType } from "@/types";
import { DataTableHeader } from "vuetify";
import { mdiCloseCircleOutline, mdiRefresh } from "@mdi/js";
import NumberFormat = Intl.NumberFormat;
const UserModule = namespace("UserModule");
const AppModule = namespace("AppModule");

@Component({
  components: {
    LabelZip: () => import("@/components/Label/LabelZip.vue"),
    LabelStatus: () => import("@/components/Label/LabelStatus.vue")
  }
})
export default class TableApplications extends Vue {
  //
  // COMPUTED
  @AppModule.State
  public currencyFormatter!: NumberFormat;
  @UserModule.Getter
  public getApplications!: Array<Application>;
  get headers(): DataTableHeader[] {
    return [
      {
        value: "item",
        text: this.$t("pages.profile.table-headers.item") as string,
        sortable: false
      },
      {
        value: "status",
        text: this.$t("pages.profile.table-headers.status") as string,
        sortable: false
      },
      {
        value: "documents",
        text: this.$t("pages.profile.table-headers.documents") as string,
        sortable: false
      },
      {
        value: "price",
        text: this.$t("pages.profile.table-headers.price") as string,
        sortable: false
      },
      {
        value: "createdDate",
        text: this.$t("pages.profile.table-headers.created-date") as string
      }
    ];
  }
  //
  // DATA
  private loading = false;
  private icons: IconsType = {
    refresh: mdiRefresh,
    closeCircleOutline: mdiCloseCircleOutline
  };
  //
  // METHODS
  toLocaleTime(time: string) {
    return new Date(time).toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    });
  }
  openApplication(me: MouseEvent, { item }: { item: Application }) {
    window.open(`/application/${item.number}`);
  }
  //
  // LIFECYCLE HOOKS
  created() {
    this.loading = true;
    setTimeout(async () => {
      await this.$store.dispatch("UserModule/fetchApplications");
      this.loading = false;
    }, 0);
  }
}
