var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.getApplications,"headers":_vm.headers,"loading":_vm.loading,"sort-by":"createdDate","disable-filtering":"","disable-sort":"","disable-pagination":"","hide-default-footer":""},on:{"dblclick:row":_vm.openApplication},scopedSlots:_vm._u([{key:"item.documents",fn:function(ref){
var item = ref.item;
return [_c('LabelZip',{attrs:{"id":item.id,"number":item.number}})]}},{key:"item.item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.item.nameFull)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormatter.format(item.price))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('LabelStatus',{attrs:{"status":item.status}})]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toLocaleTime(item.createdDate))+" ")]}},{key:"loading",fn:function(){return [_c('v-icon',{staticClass:"mt-5 mb-2 mx-auto",attrs:{"x-large":""}},[_vm._v(_vm._s(_vm.icons.refresh))]),_c('p',{staticClass:"mb-5"},[_vm._v(_vm._s(_vm.$t("loading-data")))])]},proxy:true},{key:"no-data",fn:function(){return [_c('v-icon',{staticClass:"mt-5 mb-2 mx-auto",attrs:{"x-large":""}},[_vm._v(_vm._s(_vm.icons.closeCircleOutline))]),_c('p',{staticClass:"mb-5"},[_vm._v(_vm._s(_vm.$t("no-data")))]),_c('v-btn',{attrs:{"tile":"","color":"primary","large":"","to":{ name: 'Categories' }}},[_vm._v(" "+_vm._s(_vm.$t("pages.profile.create-first-application"))+" ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }